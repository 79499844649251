import React from "react";
import { Box } from "@chakra-ui/react";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: true,
  pauseOnHover: false,
};

const AboutUsSlider = () => {
  return (
    <Box
      top="0"
      left="0"
      right="0"
      bottom="0"
      position="absolute"
      overflow="hidden"
      minH={{ base: "calc(100vh - 113px)", md: "calc(100vh - 154px)" }}
    >
      <Slider {...settings}>
        <Box
          height="100%"
          backgroundImage="url('/img/insurefarm-our-story-one.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition={{ base: -280, md: "initial" }}
        />
        <Box
          height="100%"
          backgroundImage="url('/img/insurefarm-our-story-two.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition={{ base: -300, md: "initial" }}
        />
        <Box
          height="100%"
          backgroundImage="url('/img/insurefarm-our-story-three.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition={{ base: -340, md: "initial" }}
        />
      </Slider>
    </Box>
  );
};

export default AboutUsSlider;
