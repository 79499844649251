import React, { useRef } from "react";
import Layout from "../components/Layout";
import { Box, Text, Image, Center, Icon, Button } from "@chakra-ui/react";
import ContactSection from "../components/ContactSection";
import AboutUsSlider from "../components/AboutUsSlider";
import { motion, AnimatePresence } from "framer-motion";
import { BsArrowDown } from "react-icons/bs";
import { InView, useInView } from "react-intersection-observer";
import { AiOutlinePhone } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const AboutUsPage = ({ pathname }) => {
  const scrollToSection = useRef(null);
  const { ref: aboutRef, inView: aboutInView } = useInView({ threshold: 0.2 });
  const breakpoints = useBreakpoint();

  const onScrollToSection = () => {
    if (scrollToSection) {
      scrollToSection.current.scrollIntoView();
    }
  };

  return (
    <Layout pathname={pathname}>
      <Helmet>
        <title>Insure Farm | About Us</title>
        <meta
          name="description"
          content="We are a family business with over 20 years of combined experience in the insurance industry. We believe that what separates our licensed agents from others is what happens before and after we enroll our clients."
        />
      </Helmet>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        minH="672px"
        maxH="672px"
      >
        <Box
          position="relative"
          zIndex="9"
          marginRight={{ base: "auto", md: "6rem", lg: "8rem" }}
          marginLeft="auto"
          marginTop={{ base: "auto", md: 0 }}
          marginBottom={{ base: 32, md: 0 }}
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <Text
              as="h1"
              fontSize={{ base: "40px", sm: "56px", md: "64px", lg: "72px" }}
              color="#FFFFFF"
              fontWeight="bold"
            >
              Because we care
            </Text>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.2 }}
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <motion.div whileHover={{ scale: 1.2 }}>
              <Icon
                cursor="pointer"
                fontSize="72px"
                color="#FFFFFF"
                fontWeight="bold"
                as={BsArrowDown}
                onClick={onScrollToSection}
              />
            </motion.div>
          </motion.div>
        </Box>

        <AboutUsSlider />
      </Box>

      <Box>
        <InView triggerOnce threshold={0.4}>
          {({ inView, ref }) => (
            <Box
              ref={scrollToSection}
              as="section"
              py="6rem"
              bg="#094B46"
              minH={{ base: "calc(100vh - 113px)", md: "initial" }}
              display="flex"
              alignItems="center"
            >
              <Center ref={ref} className="wrapper-md" textAlign="center">
                {inView && (
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    initial={{ y: "4rem", opacity: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      as="p"
                      fontWeight="300"
                      fontSize={{ base: "24px", md: "32px", lg: "36px" }}
                      lineHeight={{ base: "48px", md: "56px", lg: "64px" }}
                      color="#FFFFFF"
                      my="auto"
                    >
                      We are a family business with over 20 years of combined
                      experience in the insurance industry. We believe that what
                      separates our licensed agents from others is what happens
                      before and after we enroll our clients.
                    </Text>
                  </motion.div>
                )}
              </Center>
            </Box>
          )}
        </InView>
      </Box>

      <Box ref={aboutRef} as="section" position="relative">
        <InView triggerOnce threshold={breakpoints.md ? 0.4 : 1}>
          {({ inView, ref }) => (
            <Box
              ref={ref}
              my="8rem"
              justifyContent="space-between"
              display="flex"
              alignItems="center"
              flexDir={{ base: "column", md: "row" }}
              mt={{ base: 0, md: "8rem" }}
            >
              <Image
                position="relative"
                zIndex="4"
                src="/img/insurefarm-about-us-one.jpg"
                alt="Insurefarm licensced medicare agents"
              />
              {inView && (
                <motion.div
                  initial={
                    breakpoints.md
                      ? { x: "-240px", opacity: 0 }
                      : { y: "-200px", opacity: 0 }
                  }
                  animate={
                    breakpoints.md ? { x: 0, opacity: 1 } : { y: 0, opacity: 1 }
                  }
                  transition={{ duration: 0.8 }}
                >
                  <Text
                    mt={{ base: 6, md: 0 }}
                    px={{ base: "2rem", md: "7rem" }}
                    color="#333333"
                    fontSize={{ base: "20px", md: "22px", lg: "24px" }}
                    lineHeight={{ base: "40px", md: "48px" }}
                  >
                    Our agents have no deadlines. They will sit with you and do
                    a full needs analysis to understand what is most important
                    to you. Then we will make suggestions based on your budget
                    and most importantly, your needs.
                  </Text>
                </motion.div>
              )}
            </Box>
          )}
        </InView>
        <InView triggerOnce threshold={breakpoints.md ? 0.4 : 1}>
          {({ inView, ref }) => (
            <Box
              ref={ref}
              my="8rem"
              justifyContent="space-between"
              display="flex"
              alignItems="center"
              flexDir={{ base: "column-reverse", md: "row" }}
              mt={{ base: 0, md: "8rem" }}
            >
              {inView ? (
                <motion.div
                  initial={
                    breakpoints.md
                      ? { x: "240px", opacity: 0 }
                      : { y: "-200px", opacity: 0 }
                  }
                  animate={
                    breakpoints.md ? { x: 0, opacity: 1 } : { y: 0, opacity: 1 }
                  }
                  transition={{ duration: 0.8 }}
                >
                  <Text
                    mt={{ base: 6, md: 0 }}
                    px={{ base: "2rem", md: "7rem" }}
                    color="#333333"
                    fontSize={{ base: "20px", md: "22px", lg: "24px" }}
                    lineHeight={{ base: "40px", md: "48px" }}
                  >
                    When you choose to enroll into a plan with one of our agents
                    you can expect us to stay in contact with you. We will call
                    you to check on you, and you can call us anytime you have a
                    question or need assistance with your insurance. We take
                    pride in making sure every call is answered and every
                    question is answered effectively.
                  </Text>
                </motion.div>
              ) : (
                <div />
              )}
              <Image
                position="relative"
                zIndex="4"
                src="/img/insurefarm-about-us-two.jpg"
                alt="Insurefarm licensced medicare agents"
              />
            </Box>
          )}
        </InView>

        <InView triggerOnce threshold={0.4}>
          {({ inView, ref }) => (
            <Box
              ref={ref}
              my="8rem"
              justifyContent="space-between"
              display="flex"
              alignItems="center"
              flexDir={{ base: "column", md: "row" }}
              mt={{ base: 0, md: "8rem" }}
            >
              <Image
                position="relative"
                zIndex="4"
                src="/img/insurefarm-about-us-three.jpg"
                alt="Insurefarm licensced medicare agents"
              />
              {inView && (
                <motion.div
                  initial={
                    breakpoints.md
                      ? { x: "240px", opacity: 0 }
                      : { y: "-200px", opacity: 0 }
                  }
                  animate={
                    breakpoints.md ? { x: 0, opacity: 1 } : { y: 0, opacity: 1 }
                  }
                  transition={{ duration: 0.8 }}
                >
                  <Text
                    mt={{ base: 6, md: 0 }}
                    px={{ base: "2rem", md: "7rem" }}
                    color="#333333"
                    fontSize={{ base: "20px", md: "22px", lg: "24px" }}
                    lineHeight={{ base: "40px", md: "48px" }}
                  >
                    We contact you if there are changes to your plan that you
                    need to know about. Many of our clients will stay on the
                    same plan for several years but sometimes there are times
                    when we need to help them change.
                  </Text>
                </motion.div>
              )}
            </Box>
          )}
        </InView>

        <AnimatePresence>
          {aboutInView && (
            <motion.div
              animate={{ opacity: 1, x: 0 }}
              initial={{ opacity: 0, x: "20vw" }}
              transition={{ duration: 0.8, delay: 0.4 }}
              exit={{ opacity: 0, transition: { duration: 0.2, delay: 0 } }}
              style={
                breakpoints.md
                  ? {
                      position: "fixed",
                      top: "70%",
                      right: "8rem",
                      zIndex: 9,
                    }
                  : {
                      position: "fixed",
                      width: "100%",
                      zIndex: 9,
                      left: 0,
                      right: 0,
                      bottom: "40px",
                      display: "flex",
                      justifyContent: "center",
                    }
              }
            >
              <Button
                boxShadow="-4px 4px 16px rgba(0, 0, 0, 0.16)"
                colorScheme="primary"
                h={{ base: "48px", md: "58px" }}
                borderRadius="40px"
                fontSize={{ base: "16px", md: "18px" }}
                px="24px"
              >
                <Icon mr="8px" as={AiOutlinePhone} /> Speak with a Medicare
                Specialist
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>

      <InView>
        {({ inView, ref }) => (
          <Box
            ref={ref}
            display="grid"
            gridTemplateColumns="repeat(4, 1fr)"
            height="40px"
          >
            <motion.div
              animate={inView && { y: 0 }}
              initial={{ y: 40 }}
              transition={{ duration: 0.8 }}
              style={{ backgroundColor: "#1E6E5A" }}
            />
            <motion.div
              animate={inView && { y: 0 }}
              initial={{ y: 40 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              style={{ backgroundColor: "#459365" }}
            />
            <motion.div
              animate={inView && { y: 0 }}
              initial={{ y: 40 }}
              transition={{ duration: 0.8, delay: 0.8 }}
              style={{ backgroundColor: "#77B76B" }}
            />
            <motion.div
              animate={inView && { y: 0 }}
              initial={{ y: 40 }}
              transition={{ duration: 0.8, delay: 1.2 }}
              style={{ backgroundColor: "#BCEBBB" }}
            />
          </Box>
        )}
      </InView>

      <Box zIndex="12" position="relative" as="section" py="6rem" bg="#094B46">
        <Center mb={{ base: 8, md: 12, lg: 16 }}>
          <Text
            as="h2"
            fontWeight="bold"
            fontSize={{ base: "32px", lg: "48px" }}
            lineHeight={{ base: "32px", lg: "48px" }}
            color="#FFFFFF"
          >
            Our Mission
          </Text>
        </Center>
        <Box className="wrapper-xl">
          <Box
            display="grid"
            gridTemplateColumns={{ base: "none", md: "1fr 1fr" }}
            gridTemplateRows={{ base: "auto auto", md: "none" }}
            gridColumnGap={{ base: 0, md: "40px" }}
            gridRowGap={{ base: "24px", md: 0 }}
          >
            <Text
              color="#FFFFFF"
              fontSize={{ base: "20px", md: "22px", lg: "24px" }}
              lineHeight="48px"
            >
              Our mission as Medigap insurance agents is to help our nations
              seniors navigate through the complexities of Medicare and
              educating them on all of their options. Many seniors have a
              difficult time understanding Medicare so you're not alone.
            </Text>
            <Text
              color="#FFFFFF"
              fontSize={{ base: "20px", md: "22px", lg: "24px" }}
              lineHeight="48px"
            >
              Our agents spend as much time as they need to perform a full needs
              analysis to understand what is most important to you. We are
              committed to you and want you to have an exceptional experience
              when it comes to understanding and choosing your best option.
            </Text>
          </Box>
        </Box>
      </Box>

      <Box overflow="hidden">
        <ContactSection />
      </Box>
    </Layout>
  );
};

export default AboutUsPage;
